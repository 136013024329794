import classNames from "classnames";


type TablePercentProps = {
    percent: number;
    threshold: number;
};


console.log("c", classNames)


export function TablePercent({percent, threshold} : TablePercentProps) {
    if(percent === undefined || percent === null) {
        return <span></span>
    }
    return <span className={classNames("p-1 rounded", {
        "bg-success-subtle": percent <= threshold,
        "bg-danger-subtle": percent > threshold
    })}>
        {percent}%
    </span>
}