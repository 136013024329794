import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { removeEmpties } from "../../helper"
import { useCiviciList } from "../../hooks/civici"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

const Civici = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const civici = useCiviciList(
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
          <div className="page-title">
            <Link to="/dati-base">{"Dati di base"}</Link>
            {` / Civici`}
          </div>
          <Search
            value={uiFilters.search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
            }
          />
        </div>
      </div>

      <div className="p-3">
        {civici ? (
          <div className="">
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info">
                  <th>{"Loc ref."}</th>
                  <th>{"Num"}</th>
                  <th>{"Toponimo"}</th>
                  <th>{"Codice Via"}</th>
                  <th>{"Distanza"}</th>
                </tr>
              </thead>
              <tbody>
                {civici.data?.results.map((civico) => (
                  <tr key={civico.loc_ref}>
                    <td>{civico.loc_ref}</td>
                    <td>{civico.num}</td>
                    <td>{civico.toponym}</td>
                    <td>{civico.streetcode}</td>
                    <td>{civico.distanza}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={civici?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default Civici
