import get from "lodash/get"
import { Link } from "react-router-dom"
import MyDatePicker from "../../components/MyDataPicker"
import Paginator from "../../components/Paginator"
import SchedaSelector from "../../components/SchedaSelector"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useReportSchedeList } from "../../hooks/reportscheda"
import { useQsFilters } from "../../hooks/useFilters"
import { Scheda } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  tipo: params.get("tipo") ?? "",
  servizio: params.getAll("servizio") ?? [],
  scheda: params.getAll("scheda") ?? [],
  inizio_val: params.get("inizio_val") ?? "",
  fine_val: params.get("fine_val") ?? "",
})

const Reports = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const { data: reports } = useReportSchedeList(
    removeEmpties({
      tipo: filters.tipo,
      servizio: filters.servizio,
      scheda: filters.scheda,
      inizio_val: filters.inizio_val,
      fine_val: filters.fine_val,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="page-title">Reports</div>
        <div className="d-flex flex-row align-items-center">
          <div className="col p-2">
            <MyDatePicker
              type="inizio"
              startDate={filters.inizio_val}
              endDate={filters.fine_val}
              onChange={(date) => {
                if (date) setFilters({ inizio_val: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ inizio_val: "" })
              }}
            />
          </div>
          <div className="col p-2">
            <MyDatePicker
              type="fine"
              startDate={filters.inizio_val}
              endDate={filters.fine_val}
              onChange={(date) => {
                if (date) setFilters({ fine_val: mkStringfromDate(date, "YYYY-MM-DD"), page: 1 })
                else setFilters({ fine_val: "" })
              }}
            />
          </div>

          <div className="col row p-2 d-flex align-items-center">
            <label className="col-sm-2 col-form-label-sm">{"Tipo"}</label>
            <div className="col-sm-9">
              <select
                value={filters.tipo}
                className="form-control col-sm-6 form-control-sm"
                onChange={(v) => setFilters({ tipo: v.currentTarget.value, page: 1 })}
              >
                <option value="n">{"Caricamento del non eseguito"}</option>
                <option value="p">{"Caricamento PdS"}</option>
                <option value="m">{"Monitoraggio"}</option>
              </select>
            </div>
          </div>

          <div className="col p-2">
            <SchedaSelector
              multiple
              onChange={(values: Scheda[]) => {
                const v = values.map((x) => x.id.toString())
                if (v.length > 0) setFiltersDebounced({ scheda: v, page: 1 })
                else setFiltersDebounced({ scheda: [] })
              }}
              currentFilter={filters.scheda}
            />
          </div>
        </div>
      </div>

      <div className="p-3">
        {reports ? (
          <div>
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info">
                  <th>ID</th>
                  <th>Scheda</th>
                  <th>Servizio</th>
                  <th>Tipo</th>
                  <th>Data caricamento</th>
                  <th>Inizio validità</th>
                  <th>Fine validità</th>
                  <th>Dettagli</th>
                </tr>
              </thead>
              <tbody>
                {reports.results.map((report) => (
                  <tr key={report.id}>
                    <td>{report.id}</td>
                    <td>{get(report, "scheda_data.codice_scheda")}</td>
                    <td>{get(report, "servizio_data.codice_servizio")}</td>
                    <td>{report.tipo_display}</td>
                    <td>{mkStringfromDate(report.timestamp, "DD/MM/YYYY HH:mm")}</td>
                    <td>{mkStringfromDate(report.inizio_val, "DD/MM/YYYY")}</td>
                    <td>{mkStringfromDate(report.fine_val, "DD/MM/YYYY")}</td>
                    <td>
                      {(report.tipo === 'm' && report.servizio === null) ? <Link to={`/reports-monitoraggio/${report.id}`}>Visualizza</Link> :  <Link to={`/reports/${report.id}`}>Visualizza</Link>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator count={reports?.count ?? 0} currentPage={uiFilters.page} goToPage={(page) => setFilters({ page })} />
      </div>
    </StandardLayout>
  )
}

export default Reports
