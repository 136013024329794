import dayjs from "dayjs"
import { Field, Form, Formik } from "formik"
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "react-bootstrap"
import DateField from "../../components/Fields/DateField"
import { FileField } from "../../components/Fields/FileField"
import GenericFormError from "../../components/Fields/GenericFormError"
import { transformErrorForForm } from "../../components/Fields/utils"
import { useUploadPDS, useUploadRipianificato } from "../../hooks/catalogoservizi"

type UploadModalProps = {
  isOpen: boolean
  toggle?: () => void
  onClosed: () => void
  idServizio: number
  fineValRequired?: boolean
  title?: string
  typeLoad: "caricamento_pds" | "caricamento_ripianificato"
  onUpload?: () => void
}

export default function UploadModal({
  isOpen,
  toggle,
  onClosed,
  idServizio,
  fineValRequired = false,
  title = "Caricamento PDS",
  typeLoad = "caricamento_pds",
  onUpload,
}: UploadModalProps) {
  // const { tokens } = useAuth()
  // const uploadPDS = async (values: LoadPDS) => {
  //   const data = new FormData()
  //   for (const [key, value] of Object.entries(values)) {
  //     data.append(key, value)
  //   }
  //   await fetchApi(`${API_URL}/servizi/${idServizio}/${typeLoad}/`, {
  //     method: "POST",
  //     body: data,
  //     headers: {
  //       Authorization: `Bearer ${tokens?.access}`,
  //     },
  //   })
  // }

  const { mutateAsync: uploadPDS } = useUploadPDS(idServizio)
  const { mutateAsync: uploadRipianificato } = useUploadRipianificato(idServizio)

  return (
    <Formik
      initialValues={{
        inizio_val: dayjs().format("YYYY-MM-DD"),
        fine_val: undefined,
        csv_file: undefined,
      }}
      onSubmit={(values, actions) => {
        if (typeLoad === "caricamento_pds") {
          return uploadPDS(values)
            .then(() => {
              toggle?.()
              onUpload?.()
            })
            .catch((err) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions.setErrors(transformErrorForForm(err) as any)
            })
        } else if (typeLoad === "caricamento_ripianificato") {
          return uploadRipianificato(values)
            .then(() => {
              toggle?.()
              onUpload?.()
            })
            .catch((err) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions.setErrors(transformErrorForForm(err) as any)
            })
        }
      }}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader className="flex-row justify-content-between bg-primary text-white">
              <span>{title}</span>
            </ModalHeader>
            <ModalBody>
              <div className="mb-3">
                <Field label="Inizio validità *" className="form-control form-control-sm text" component={DateField} name="inizio_val" required></Field>
              </div>
              <div className="mb-3">
                <Field
                  label={`Fine validità ${fineValRequired ? "*" : ""}`}
                  className="form-control form-control-sm text"
                  component={DateField}
                  name="fine_val"
                  required={fineValRequired}
                ></Field>
              </div>

              <Field
                label={"File PDS *"}
                component={FileField}
                type="file"
                name="csv_file"
                accept=".csv"
                required
              ></Field>
              <GenericFormError errors={errors} badRequestError="Tracciato non valido"></GenericFormError>
            </ModalBody>
            <ModalFooter>
              {isSubmitting && <Spinner variant="primary" />}
              {!isSubmitting && (
                <button className="btn btn-primary" type="submit">
                  Carica
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}
