import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { removeEmpties } from "../../helper"
import { useClassOggettiList } from "../../hooks/classificazionioggetti"
import { useQsFilters } from "../../hooks/useFilters"
import { Classificazione } from "../../types"
import HeaderClassificazione from "./HeaderClassificazione"

type ClassificazioneListProps = {
  classificazione: Classificazione
}

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
  nome_via: params.get("nome_via") ?? "",
})

const ClassificazioneList = ({ classificazione }: ClassificazioneListProps) => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const classificazioniOggetti = useClassOggettiList(
    removeEmpties({
      search: filters.search,
      nome_via: filters.nome_via,
      classificazione: classificazione.id.toString(),
      page: String(filters.page),
    })
  )
  return (
    <StandardLayout>
      {classificazione && <HeaderClassificazione classificazione={classificazione} open="list" />}
      <div className="p-3">
        <div className="row pb-3">
          <div className="col-md-3 offset-md-6">
            <Search
              value={uiFilters.search}
              placeholder="Ricerca loc_ref o streetcode"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
              }
            />
          </div>
          <div className="col-md-3">
            <Search
              value={uiFilters.nome_via}
              placeholder="Ricerca via"
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setFiltersDebounced({ nome_via: e.currentTarget.value, page: 1 })
              }
            />
          </div>
        </div>
        {classificazioniOggetti.data ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr className="table-info">
                  <th>Street code</th>
                  <th>Nome via</th>
                  <th>Loc_ref</th>
                  <th>Classe</th>
                </tr>
              </thead>
              <tbody>
                {classificazioniOggetti.data.results.map((classificazioneOggetto) => (
                  <tr key={classificazioneOggetto.id}>
                    <td>{classificazioneOggetto.streetcode}</td>
                    <td>{classificazioneOggetto.nome_via}</td>
                    <td>{classificazioneOggetto.loc_ref}</td>
                    <td>{classificazioneOggetto.nome_classe}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={classificazioniOggetti?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default ClassificazioneList
