import classNames from "classnames"
import { FieldProps } from "formik"
import { Form, FormGroup } from "react-bootstrap"
import { mkDatefromString, mkStringfromDate } from "../../helper"

interface DateField extends FieldProps {
  label?: string
  style: React.CSSProperties
}
export default function DateField({ field, form, label, style, ...props }: DateField) {
  
  return (
    <FormGroup className="datepicker-full-width">
      {label && <Form.Label className="text-right">{label}</Form.Label>}
      <div style={style}>
        <input
          name={field.name}
          value={field.value}
          className={classNames("form-control", {
            "is-invalid": form.touched[field.name] && !!form.errors[field.name],
          })}
          type="date"
          onChange={(e) => {
            const date = mkDatefromString(e.target.value)
            if (!date || date.toDateString() === "Invalid Date") {
              form.setFieldValue(field.name, "")
            } else {
              form.setFieldValue(field.name, mkStringfromDate(date, "YYYY-MM-DD"))
            }
          }}
          onBlur={field.onBlur}
          {...props}
        />
        {/* OLD WAY */}
        {/* <DatePicker
          autoComplete="off"
          className={classNames("form-control", {
            "is-invalid": form.touched[field.name] && !!form.errors[field.name],
          })}
          showYearDropdown
          locale={"it"}
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={80}
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          dateFormat="dd-MM-yyyy"
          onChange={(date) => {
            if (!date || date.toDateString() === "Invalid Date") {
              form.setFieldValue(field.name, "")
            } else {
              form.setFieldValue(field.name, mkStringfromDate(date, "YYYY-MM-DD"))
            }
          }}
          {...props}
        /> */}
        {/* {form.touched[field.name] && form.errors[field.name] && (
          <div></div>
          <FormFeedback className="d-block">{form.errors[field.name]}</FormFeedback>
        )} */}
      </div>
    </FormGroup>
  )
}
