import { saveAs } from "file-saver"
import { useCallback } from "react"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { useDownloadClassificazioneCSV } from "../../hooks/classificazioni"
import { ClasseOggetti, Classificazione } from "../../types"
import HeaderClassificazione from "./HeaderClassificazione"

type ClassificazioneDettaglioProps = {
  classificazione: Classificazione
}
export default function ClassificazioneDettaglio({ classificazione }: ClassificazioneDettaglioProps) {
  const { mutateAsync: exportCSV } = useDownloadClassificazioneCSV(classificazione.id)

  const downloadCSV = useCallback(() => {
    exportCSV().then((docBinary) => {
      if (docBinary !== null) {
        saveAs(docBinary, `classificazione-${classificazione.id}.csv`)
      }
    })
  }, [classificazione.id])

  return (
    <StandardLayout>
      {classificazione && (
        <HeaderClassificazione
          classificazione={classificazione}
          open="info"
          onClick={() => downloadCSV()}
          hasSchedaFilter
        />
      )}
      <div className="p-3">
        {classificazione && (
          <div className="mt-2">
            <PropertyList
              propLists={[
                [
                  ["Nome", classificazione.nome],
                  ["Descrizione", classificazione.descrizione],
                  ["Riferimento", classificazione.riferimento],
                ],
                [
                  ["Tipo oggetto", classificazione.tipo_oggetto],
                  ["Oggetti totali", classificazione.numero_oggetti_totali],
                  [
                    "Oggetti classificati",
                    <span
                      className={`badge ${
                        classificazione.numero_oggetti_classificati === classificazione.numero_oggetti_totali
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                    >
                      {classificazione.numero_oggetti_classificati}
                    </span>,
                  ],
                ],
              ]}
            ></PropertyList>

            <div className="mt-4">
              <div className="page-title">Classi di oggetti</div>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className="table-info">
                    <th>Nome</th>
                    <th>Frequenza</th>
                    <th>Tempo recupero</th>
                    <th>Numero oggetti</th>
                  </tr>
                  {classificazione?.classi_oggetti_annotate?.map((classe: ClasseOggetti) => (
                    <tr key={classe.id}>
                      <td>{classe.nome}</td>
                      <td>
                        {classe.frequenza_numero} / {classe.frequenza_periodo}
                      </td>
                      <td>{classe.tempo_recupero_sla_ore}</td>
                      <td>{classe.numero_oggetti}</td>
                    </tr>
                  ))}
                </thead>
              </table>
            </div>
          </div>
        )}
      </div>

      <div className="sticky-footer bg-light border-top"></div>
    </StandardLayout>
  )
}
