import { Link, useParams } from "react-router-dom"
import Paginator from "../../components/Paginator"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { useQsFilters } from "../../hooks/useFilters"
import { useReportSchedeList } from "../../hooks/reportscheda"
import { Scheda } from "../../types"
import HeaderServizi from "./HeaderServizi"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

type Props = {
  schedaServizio: Scheda
}

export default function DettaglioServizio({ schedaServizio }: Props) {
  const { filters, uiFilters, setFilters } = useQsFilters(initFilters)
  const idServizio = parseInt(useParams().idServizio!)
  const servizio = schedaServizio.servizi?.find((ser) => ser.id === idServizio)

  const caricamenti = useReportSchedeList(
    removeEmpties({
      search: filters.search,
      servizio: idServizio.toString(),
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      {schedaServizio && servizio && <HeaderServizi schedaServizio={schedaServizio} servizio={servizio} open="info" />}

      {caricamenti ? (
        <div className="px-3 pb-3">
          <div className="page-title mb-2">Reports</div>
          <table className="table table-hover table-bordered">
            <thead>
              <tr className="table-info">
                <th>ID</th>
                <th>Tipo</th>
                <th>Operazione</th>
                <th>Data caricamento</th>
                <th>Inizio validità</th>
                <th>Fine validità</th>
                <th>Dettagli</th>
              </tr>
            </thead>
            <tbody>
              {caricamenti.data?.results.map((report) => (
                <tr key={report.id}>
                  <td>{report.id}</td>
                  <td>{report.tipo}</td>
                  <td>{report.operazione}</td>
                  <td>{mkStringfromDate(report.timestamp, "YYYY-MM-DD")}</td>
                  <td>{mkStringfromDate(report.inizio_val, "YYYY-MM-DD")}</td>
                  <td>{mkStringfromDate(report.fine_val, "YYYY-MM-DD")}</td>
                  <td>
                    <Link to={`/reports/${report.id}`}>Visualizza</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={caricamenti?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}
