import { FieldProps, getIn } from "formik"
import React, { useCallback, useEffect, useRef } from "react"
import { Col, Form } from "react-bootstrap"
import { IoMdCloseCircle } from "react-icons/io"

interface FileFieldProps extends FieldProps {
  rowLayout?: boolean
  label: string
}

export const FileField = ({ field, form, label, ...props }: FileFieldProps) => {
  const { errors } = form
  const error = getIn(errors, field.name)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(field.name, e.target?.files?.[0])
    },
    [field.name, form]
  )

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleReset = useCallback(() => {
    setFileName(null)
    if (!hiddenFileInput.current) return
    hiddenFileInput.current.value = ""
  }, [field.name, form])

  const [fileName, setFileName] = React.useState<string | null>(null)

  const handleClick = () => {
    if (!hiddenFileInput.current) return
    hiddenFileInput.current.click()
  }

  const handleFocus = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleChangeAndSetState = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      const name = e.target.files?.[0].name
      console.log(name)
      if (name) {
        setFileName(name)
      } else {
        setFileName(null)
      }
    },
    [handleChange]
  )

  useEffect(() => {
    hiddenFileInput.current?.addEventListener("focus", (e) => {
      e.preventDefault()
      e.stopPropagation()
    })
  }, [])

  return (
    <Col sm={8}>
      <Form.Group controlId="formFile" className="mb-3">
        {label && <Form.Label>{label}</Form.Label>}
        <React.Fragment>
          <div className="d-flex flex-row align-items-center">
            <button type="button" className="btn btn-sm btn-primary me-2" onClick={handleClick}>
              Seleziona file
              <Form.Control
                required
                type="file"
                onChange={handleChangeAndSetState}
                {...props}
                ref={hiddenFileInput}
                style={{ opacity:0, width: 0, height:0, padding: 0 }}
              />
            </button>
            <Form.Label>{fileName}</Form.Label>
            {fileName && (
              <button type="button" className="btn btn-link" onClick={handleReset} onFocusCapture={handleFocus}>
                <IoMdCloseCircle />
              </button>
            )}
          </div>
          <Form.Text className="text-muted"></Form.Text>

          <Form.Control.Feedback type="invalid" className="d-block">
            {error}
          </Form.Control.Feedback>
        </React.Fragment>
      </Form.Group>
    </Col>
  )
}
