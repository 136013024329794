import { Link } from "react-router-dom"
import SchedaSelector from "../../components/SchedaSelector"
import { useQsFilters } from "../../hooks/useFilters"
import { Classificazione, Scheda } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  scheda: params.get("scheda") ?? "",
})

type HeaderClassificazioneProps = {
  classificazione: Classificazione
  open: "info" | "list" | "map"
  onClick?: () => void
  hasSchedaFilter?: boolean
}
export default function HeaderClassificazione({
  classificazione,
  open,
  onClick,
  hasSchedaFilter = false,
}: HeaderClassificazioneProps) {
  const { filters, setFilters } = useQsFilters(initFilters)
  return (
    <div className="sticky-header bg-white p-3 shadow-sm">
      <div className="d-flex justify-content-between">
        <div className="page-title mb-2">
          <Link to="/classificazioni">Classificazioni</Link>
          {` / ${classificazione?.nome}`}
        </div>
        {hasSchedaFilter && (
          <SchedaSelector
            onChange={(values: Scheda[]) => {
              const v = values.map((x) => x.id.toString())[0]
              if (v?.length > 0) setFilters({ scheda: v })
              else setFilters({ scheda: "" })
            }}
            currentFilter={filters.scheda}
            containerClassName="d-flex flex1 align-items-center"
            labelClassName="px-4"
            selectorClassName=""
          />
        )}
      </div>

      {classificazione && (
        <div className="d-flex justify-content-between">
          <div>
            <Link
              className={`btn btn-sm ${open === "info" ? "btn-primary" : " btn-default"}`}
              to={`/classificazioni/${classificazione.id}/`}
            >
              Informazioni
            </Link>{" "}
            <Link
              className={`btn btn-sm ${open === "list" ? "btn-primary" : " btn-default"}`}
              to={`/classificazioni/${classificazione.id}/list`}
            >
              Elenco oggetti
            </Link>{" "}
            <Link
              className={`btn btn-sm ${open === "map" ? "btn-primary" : " btn-default"}`}
              to={`/classificazioni/${classificazione.id}/map`}
            >
              Mappa oggetti
            </Link>
          </div>
          {onClick && (
            <div>
              <button className="btn btn-sm btn-default" onClick={onClick}>
                Scarica CSV
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
