import { Link, useParams } from "react-router-dom"
import StandardLayout from "../../components/StandardLayout"
import { useReportScheda, useReportSchedaJson } from "../../hooks/reportscheda"
import { TablePercent } from "../../components/TablePercents"



const headers_map = {
  "giorno": "Giorno",
  "non_eseguito": "Non eseguito",
  "pianificato": "Pianificato",
  "eseguito_pianificato": "Eseguito pianificato",
  "recuperato_sla": "Recuperato SLA",
  "recuperato_non_sla": "Recuperato non SLA", 
}

function HeaderTitle({header} : { header: keyof typeof headers_map }) {
  
  
  const keys = Object.keys(headers_map)
  return keys.includes(header) ? <div>{headers_map[header]}</div> : <div>{header}</div>
}







export default function ReportDettaglio() {
  const idReport = useParams().id!

  const { data: report } = useReportScheda(idReport)

  const { data: dettagli } = useReportSchedaJson(idReport)

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="page-title">
          <Link to="/reports">Reports</Link> / {report?.id}
        </div>
      </div>
      <div className="p-3">
        {/* {report && (
          <div className="mt-2">
            <PropertyList
              propLists={[
                [
                  ["Id", report.id],
                  ["Tipo", report.tipo_display],
                  ["Scheda", report.scheda_data?.codice_scheda],
                  ["Servizio", report.servizio_data?.codice_servizio],
                ],
                [
                  ["Operazione", report.operazione ?? "-"],
                  ["Inizio validità", mkStringfromDate(report.inizio_val, "DD/MM/YYYY")],
                  ["Fine validità", mkStringfromDate(report.fine_val, "DD/MM/YYYY")],
                  ["Data caricamento", mkStringfromDate(report.timestamp, "DD/MM/YYYY HH:mm")],
                ],
              ]}
            ></PropertyList>
          </div>
        )} */}

        {dettagli && (
          <div className="mt-4">
            <table className="table">
              <thead>
                <tr className="table-info">
                  {dettagli.header.map((header, index) => {
                    const valoreSoglia = dettagli.subheader[index]
                    const isNumber = index > 1
                    return (
                      <th key={index} className="p-2 py-3 ">
                        <HeaderTitle header={header as keyof typeof headers_map}></HeaderTitle>
                        <div className="text-muted fw-normal">
                          {valoreSoglia ? `${valoreSoglia}${isNumber ? "%" : ""}` : <span>&nbsp;</span>}
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>

              <tbody>
                {dettagli.data.map((row, index) => (
                  <tr key={index}>
                    {dettagli.header.map((header, headIndex) => {
                      const isSoglia = headIndex > 1
                      const sogliaValue = dettagli.soglie[index][header]

                      return (
                        <td key={headIndex} className="p-2 py-3">
                          <div>{row[header]}</div>
                          
                          {header === "giorno" ? (
                            <div>&nbsp;</div>
                          ) : isSoglia ? (
                            <TablePercent
                              percent={sogliaValue as number}
                              threshold={dettagli.subheader[headIndex] as number}
                            />
                          ) : (
                            <div>{sogliaValue || <span>&nbsp;</span>}</div>
                          )}
                        </td>
                      )
                    })}
                  </tr>
                ))}
                <tr>
                  {dettagli.header.map((header, headIndex) => (
                    <td key={headIndex} className="p-2 py-3">
                      <div>{dettagli.totali[header] !== undefined ? dettagli.totali[header] : <span>&nbsp;</span>}</div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </StandardLayout>
  )
}
