import { Field, FieldArray, Form, Formik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useMemo } from "react"
import { Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../../auth"
import DateField from "../../components/Fields/DateField"
import GenericFormError from "../../components/Fields/GenericFormError"
import { fixInitialValuesNull, transformErrorForForm } from "../../components/Fields/utils"
import ModalConfirm from "../../components/ModalConfirm"
import { mkStringfromDate } from "../../helper"
import { useUpdatePDS } from "../../hooks/catalogoservizi"
import { Dati, Piano } from "../../types"

type ModificaPianoFormProps = {
  idServizio: number
  pianiAttivi?: Piano[]
  pianiBozza?: Piano[]
  refetch: () => void
}

export default function ModificaPianoForm({ idServizio, pianiAttivi, pianiBozza, refetch }: ModificaPianoFormProps) {
  const { user } = useAuth()
  const [confirmModalState, confirmModalActions] = useModalTrigger()

  const initialValues = useMemo(() => {
    if (!pianiAttivi) {
      return { piani_attivi: [] }
    }
    const out = {
      piani_attivi: pianiAttivi.map((item: Dati) => ({
        id: item.id,
        fine_val: item.fine_val,
        fine_val_initial: item.fine_val,
        inizio_val: item.inizio_val,
        totale_lavorazioni_piano: item.totale_lavorazioni_piano,
        ripianificato: item.ripianificato,
      })),
    }
    return fixInitialValuesNull(out)
  }, [pianiAttivi])

  const { mutateAsync: updatePDS } = useUpdatePDS(idServizio)

  if (!pianiAttivi) {
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        return updatePDS(values)
          .then(() => {
            refetch()
            confirmModalActions.toggle()
          })
          .catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actions.setErrors(transformErrorForForm(err) as any)
          })
      }}
    >
      {({ values, errors, isSubmitting, submitForm }) => (
        <Form>
          {pianiAttivi && pianiAttivi.length > 0 && (
            <table className="table table-hover table-bordered">
              <thead>
                <tr className="table-info">
                  <th>Id</th>
                  <th>Tipo piano</th>
                  <th>Inizio val.</th>
                  <th>Fine val.</th>
                  <th>Modifica fine val.</th>
                  <th>Quantita tot.</th>
                  <th>Numero vie</th>
                  <th>N. vie su grafo</th>
                  <th>N. oggetti su grafo</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="piani_attivi"
                  render={() => (
                    <>
                      {values.piani_attivi.map((piano: Dati, index: number) => (
                        <tr key={piano.id}>
                          <td>
                            <Link to={`/programmazioni/${piano.id}`}>{piano.id}</Link>
                          </td>
                          <td>{piano.ripianificato ? "Ripianificato" : "Pianificato"}</td>

                          <td>{mkStringfromDate(piano.inizio_val, "DD/MM/YYYY")}</td>
                          <td>{mkStringfromDate(piano.fine_val_initial, "DD/MM/YYYY")}</td>
                          <td>
                            <Field
                              className="form-control form-control-sm text"
                              placeholder={"Inserire data fine"}
                              component={DateField}
                              name={`piani_attivi[${index}].fine_val`}
                            ></Field>
                          </td>
                          <td>{piano.totale_lavorazioni_piano.quantita ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano.numero_vie ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano.numero_vie_agganciate ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano.numero_oggetti ?? ""}</td>
                        </tr>
                      ))}
                    </>
                  )}
                />
              </tbody>
            </table>
          )}

          <GenericFormError errors={errors}></GenericFormError>

          {!user?.is_operatore_comune && (
            <div>
              {isSubmitting && <Spinner variant="primary" />}
              {!isSubmitting && (
                <button
                  type="button"
                  className="btn-sm btn btn-danger"
                  disabled={
                    (!pianiBozza || !pianiBozza.length) && !values.piani_attivi.filter((x) => x.fine_val).length
                  }
                  onClick={() => confirmModalActions.open("dummy")}
                >
                  {"Conferma modifiche piano"}
                </button>
              )}
            </div>
          )}
          {confirmModalState.value && pianiBozza && (
            <ModalConfirm
              isOpen={confirmModalState.isOpen}
              toggle={confirmModalActions.toggle}
              onClosed={confirmModalActions.onClosed}
              title="Conferma modifica piano"
              okCallBack={submitForm}
              body={getPDSConfirmText(pianiBozza, values.piani_attivi)}
              isSubmitting={isSubmitting}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

function getPDSConfirmText(pianiBozza: Piano[], modifichePianiAttivi?: Partial<Piano>[]) {
  const pianiAttiviModificati = modifichePianiAttivi?.filter((mod) => {
    return mod.fine_val !== mod.fine_val_initial
  })

  return (
    <div>
      <p>{`Proseguendo saranno apportate le seguenti modifiche al piano dei servizi:`}</p>
      <ul>
        {pianiBozza && pianiBozza.length > 0 && <li>{`${pianiBozza.length} piani in bozza saranno attivati`}</li>}
        {pianiAttiviModificati?.map((modifica) => (
          <li key={modifica.id}>
            {`Per il piano con id ${modifica.id} verrà modificata la fine validità alla data
            ${mkStringfromDate(modifica.fine_val, "DD-MM-YYYY")}`}
          </li>
        ))}
      </ul>
    </div>
  )
}
