import dayjs from "dayjs"
import "dayjs/locale/it"
import "globalize/lib/cultures/globalize.culture.it"
import { useMemo } from "react"
import {
  Calendar as BigCalendar,
  DateFormat,
  DateLocalizer,
  dayjsLocalizer,
  FormatInput,
  Formats,
} from "react-big-calendar"
import { Link } from "react-router-dom"
import SchedaSelector from "../../components/SchedaSelector"
import StandardLayout from "../../components/StandardLayout"
import ViaSelector from "../../components/ViaSelector"
import { mkStringfromDate, removeEmpties } from "../../helper"
import { usePlainEventList } from "../../hooks/useEvent"
import { useQsFilters } from "../../hooks/useFilters"
import { CalendarEvent, Scheda } from "../../types"

dayjs.locale("it")
const localizer = dayjsLocalizer(dayjs)

const Eve = ({ event }: { event: CalendarEvent }) => {
  const { filters } = useQsFilters(initFilters)

  const url = `/overview/pianificato/${event.id_scheda}/${event.giorno}/?tab=map&page=1&${
    filters.vie.length !== 0 ? `filterVia=${filters.vie}&selectVia=${filters.vie}` : ""
  }`
  const urlProg = `/overview/pianificato/${event.id_scheda}/${event.giorno}/?tab=table&page=1&${
    filters.vie.length !== 0 ? `filterVia=${filters.vie}&selectVia=${filters.vie}` : ""
  }`

  return (
    <div className="p-0">
      <div className="mb-1">{`${event.codice_scheda} (${event.quantita})`}</div>
      <Link to={url} className="text-decoration-none me-2">
        <small>Mappa</small>
      </Link>{" "}
      <Link to={urlProg} className="text-decoration-none">
        <small>Programma</small>
      </Link>
    </div>
  )
}

const initFilters = (params: URLSearchParams) => ({
  vie: params.getAll("vie") ?? [],
  scheda: params.getAll("scheda") ?? [],
  data_inizio: params.get("data_inizio") ?? dayjs().startOf("month").format("YYYY-MM-DD"),
  data_fine: params.get("data_fine") ?? dayjs().endOf("month").format("YYYY-MM-DD"),
})

export default function Calendar() {
  const { filters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const { data: events } = usePlainEventList(
    removeEmpties({
      vie: filters.vie,
      scheda: filters.scheda,
      data_inizio: filters.data_inizio,
      data_fine: filters.data_fine,
    })
  )

  const formats = useMemo<Formats>(
    () => ({
      dateFormat: "D" as DateFormat, // the 'date' on each day cell of the 'month' view
      weekdayFormat: (date: FormatInput, culture: string | undefined, localizer: DateLocalizer | undefined) =>
        localizer ? localizer.format(date, "ddd", culture) : "", // the day of the week header in the 'month' view
      dayFormat: (date: FormatInput, culture: string | undefined, localizer: DateLocalizer | undefined) =>
        localizer ? localizer.format(date, "dddd D", culture) : "", // the day header in the 'week' and 'day' (Time Grid) views
      timeGutterFormat: (date: FormatInput, culture: string | undefined, localizer: DateLocalizer | undefined) =>
        localizer ? localizer.format(date, "HH:mm", culture) : "", // the time in the gutter in the Time Grid views
    }),
    []
  )

  return (
    <StandardLayout classNameContent="overflow-hidden pb-0">
      <div className="d-flex flex-column" style={{ height: "100%" }}>
        <div className="sticky-header p-3 bg-light shadow-sm">
          <div className="d-flex justify-content-between">
            <h5 className="mb-0">Calendario</h5>
            <div className="d-flex align-items-center">
              <div className="col p-2">
                <ViaSelector
                  currentFilter={filters.vie}
                  onChange={(values) => {
                    const v = values.map((x) => x.streetcode.toString())
                    if (v.length > 0) setFiltersDebounced({ vie: v })
                    else setFiltersDebounced({ vie: [] })
                  }}
                />
              </div>
              <div className="col p-2">
                <SchedaSelector
                  multiple
                  onChange={(values: Scheda[]) => {
                    const v = values.map((x) => x.id.toString())
                    if (v.length > 0) setFiltersDebounced({ scheda: v })
                    else setFiltersDebounced({ scheda: [] })
                  }}
                  currentFilter={filters.scheda}
                />
              </div>
            </div>
          </div>
        </div>

        <BigCalendar
          localizer={localizer}
          culture={"it"}
          style={{ padding: "16px" }}
          formats={formats}
          components={{ event: Eve }}
          events={events ?? []}
          startAccessor={(e) => dayjs(e.start).toDate()}
          endAccessor={(e) => dayjs(e.end).toDate()}
          messages={{
            month: "Mese",
            day: "Giorno",
            week: "Settimana",
            today: "Oggi",
            previous: "Indietro",
            next: "Avanti",
          }}
          titleAccessor={(e: CalendarEvent) => `${e.codice_scheda} (${e.quantita})`}
          onRangeChange={(range) => {
            if (Array.isArray(range)) {
              setFilters({ data_inizio: mkStringfromDate(range[0]) })
              setFilters({ data_fine: mkStringfromDate(range[range.length - 1]) })
            } else {
              setFilters({ data_inizio: mkStringfromDate(range.start) })
              setFilters({ data_fine: mkStringfromDate(range.end) })
            }
          }}
        />
      </div>
    </StandardLayout>
  )
}
